import React from 'react'
import { IDVerification } from 'tf-checkout-react'

import Layout from '../../components/layout'
import { setTfCheckoutReactConfigs } from '../../utils/tf-checkout-config'
import Head from '../../components/head/index'
import './verification.scss'

setTfCheckoutReactConfigs()
const IDVerificationPage = () => (
  <>
    <Head title='Verification Page' />
    <Layout>
      <div className='verification-container'>
        <IDVerification />
      </div>
    </Layout>
  </>
)

export default IDVerificationPage
